import React from 'react';

import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Heading = ({ children }) => <h4 className="bold mt-4">{children}</h4>;
const Text = ({ children }) => (
  <p className="mb-0">
    <span className="text-muted d-block mt-2">{children}</span>
  </p>
);

const options = {
  renderNode: {
    [BLOCKS.HEADING_4]: (node, children) => <Heading>{children}</Heading>,
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
};

const SectionImprint = ({ title, text }) => {
  const { json } = text;
  const components = documentToReactComponents(json, options);
  return (
    <section className="section pt-5" id="imprint">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="section-title text-center">{title}</h1>
            <div className="section-title-border margin-t-20" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="mt-4 pt-4">{components}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionImprint;
