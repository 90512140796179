import React from 'react';
// import TechnologyIcon from '../TechnologyIcon';
import './Client.css';

const Client = ({ name, url, logo }) => {
  const { url: logoUrl } = logo.file;
  return (
    <div className="col-lg-2 margin-t-20">
      <a href={url} target="_blank" rel="noopener noreferrer" title={name}>
        <div className="services-box text-center hover-effect">
          <img className="clientLogo" src={logoUrl} alt={name} />
        </div>
        <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
          {name}
        </p>
      </a>
    </div>
  );
};

export default Client;
