import React from 'react';
import Technology from '../Technology';
import utils from '../../utils';

const SectionTechnologies = ({ title, subTitle, technologies }) => {
  technologies.sort(utils.sortFromAtoZ);
  return (
    <section className="section pt-5" id="technologies">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="section-title text-center">{title}</h1>
            <div className="section-title-border margin-t-20" />
            <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
              {subTitle}
            </p>
          </div>
        </div>
        <div className="row justify-content-center margin-t-30">
          {technologies.map(technology => (
            <Technology key={technology.slug} {...technology} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SectionTechnologies;
